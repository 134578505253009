interface Props {
  children: React.ReactNode;
  top?: boolean;
  bottom?: boolean;
  bg?: "bg-primary" | "bg-primarygray" | "bg-primaryblack" | "bg-white";
}

const CustomBorder = ({ children, top, bottom, bg = "bg-white" }: Props) => {
  return (
    <div
      className={`relative before:absolute before:inset-[1px] before:rounded-3xl before:bg-primary ${top ? "pt-[3px]" : ""} ${bottom ? "pb-[3px]" : ""}`}
    >
      <div className={`relative h-full rounded-3xl ${bg}`}>{children}</div>
    </div>
  );
};

export default CustomBorder;
