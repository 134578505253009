"use client";
import Link from "next/link";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import StoryPreview from "./elements/StoryPreview";
import { motion, AnimatePresence } from "framer-motion";
import CustomBorder from "./elements/CustomBorder";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { navlinks } from "@/const/navlinks";

const LockBodyScroll = () => {
  useLockBodyScroll();
  return null;
};

interface WatchNavInterface {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const WatchNav = ({ open, setOpen }: WatchNavInterface) => {
  useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);
  return null;
};

interface Props {
  mode?: "light" | "dark";
}

const Navbar = ({ mode = "light" }: Props) => {
  // TODO: Disabling latestStory for now 🥲
  const latestStory = null;
  const [navOpened, setNavOpened] = useState(false);
  const [navShrinked, setNavShrinked] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos !== 0) {
      setNavShrinked(true);
    } else {
      setNavShrinked(false);
    }
  };

  useEffect(() => {
    if (window.scrollY !== 0) {
      setNavShrinked(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div
      className={`${mode === "light" || navShrinked ? "bg-white" : "bg-transparent"} fixed left-0 top-0 z-[1] flex w-[100vw] items-center justify-between gap-1 px-4 py-2 duration-500 laptop:gap-5 laptop:px-[60px] laptop:py-3`}
    >
      <Link href="/" className="relative z-[2]">
        <img
          src={
            mode === "light" || navOpened || navShrinked
              ? "/img/logo.svg"
              : "/img/logo-white.svg"
          }
          alt="April9 Growth Tech"
          className={`h-[54px] w-[94px] duration-500 ${navShrinked ? "laptop:h-[54px] laptop:w-[94px]" : "laptop:h-[100px] laptop:w-[175px]"}`}
        />
      </Link>

      {/* ================= Desktop Navigation ================= */}
      <div
        className={`hidden flex-col duration-500 tablet:flex ${mode === "light" || navShrinked ? "text-primaryblack" : "text-white"}`}
      >
        <AnimatePresence initial={false}>
          {!navShrinked && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ type: "Inertia", duration: 0.5 }}
              className="overflow-hidden"
            >
              <div className="flex flex-wrap items-center justify-end gap-4 pb-1 text-[13px] laptop:pb-3 laptop:text-sm">
                {navlinks?.top?.items?.map(el => (
                  <Link key={el.title} href={el.href} className="nav-link">
                    {el.title}
                  </Link>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* ================= Desktop Lower Bar ================= */}
        <div
          className={`flex items-center justify-end rounded-full bg-neutral3 p-[6px] text-[13px] laptop:text-lg ${mode === "light" || navShrinked ? "" : "bg-opacity-10"}`}
        >
          <div className="flex flex-wrap items-center justify-end gap-4 px-4 laptop:px-8">
            <Link href={navlinks?.technology?.href} className="nav-link">
              {navlinks?.technology?.title}
            </Link>
            <Menu as="div">
              <Menu.Button className="nav-link">
                {navlinks?.solutions?.title}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in-out duration-500"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items className="absolute left-[16px] right-[16px] z-[1] mt-4 w-[100%-120px] pt-[3px] before:absolute before:inset-[1px] before:rounded-3xl before:bg-primary focus:outline-none laptop:left-[60px] laptop:right-[60px]">
                  <div className="relative h-full rounded-3xl bg-white p-[30px] text-primaryblack shadow-2xl laptop:p-[50px]">
                    <div className="grid gap-4 tablet:grid-cols-[2fr_1fr] laptop:grid-cols-[2fr_360px] laptop:gap-8">
                      <div>
                        <div
                          className={`group mb-12 flex py-1 text-[32px] font-bold leading-[1em]`}
                        >
                          Explore our modular software solutions by:
                        </div>
                        <div className="grid grid-cols-2 gap-8">
                          <div className="flex flex-col gap-2 laptop:gap-3">
                            <div className="mb-5 flex flex-wrap items-center gap-3">
                              <img
                                src="/img/solutions/cta-solutions-ico1.svg"
                                alt="Bullseye icon"
                              />
                              <div className="text-lg font-bold">
                                {navlinks?.goals?.title}
                              </div>
                            </div>
                            {navlinks?.goals?.items?.map(el => (
                              <Menu.Item key={el.title}>
                                {({ active }) => (
                                  <Link
                                    href={el.href}
                                    className={`${
                                      active
                                        ? "after:w-24 after:opacity-100"
                                        : ""
                                    } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300`}
                                  >
                                    {el.title}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                          <div className="flex flex-col gap-3">
                            <div className="mb-5 flex flex-wrap items-center gap-3">
                              <img
                                src="/img/solutions/cta-solutions-ico2.svg"
                                alt="Building icon"
                              />
                              <div className="text-lg font-bold">
                                {navlinks?.industries?.title}
                              </div>
                            </div>
                            {navlinks?.industries?.items?.map(el => (
                              <Menu.Item key={el.title}>
                                {({ active }) => (
                                  <Link
                                    href={el.href}
                                    className={`${
                                      active
                                        ? "after:w-24 after:opacity-100"
                                        : ""
                                    } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300`}
                                  >
                                    {el.title}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-7 rounded-3xl bg-neutral3 px-6 py-8">
                        <div className="flex flex-wrap items-center justify-between">
                          <div className="text-base font-bold">
                            Latest from April9
                          </div>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                href="/success-stories"
                                className={`${
                                  active ? "border-neutral2 bg-neutral2" : ""
                                } rounded-full border border-neutral1 px-3 py-[3px] text-[15px] duration-300 hover:border-neutral2 hover:bg-neutral2`}
                              >
                                View all
                              </Link>
                            )}
                          </Menu.Item>
                        </div>

                        {/* <StoryPreview
                          path="/success-stories"
                          item={latestStory}
                        /> */}
                      </div>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative">
              <Menu.Button className="nav-link">
                {navlinks?.resources.title}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in-out duration-500"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items className="absolute -right-[30px] z-[1] mt-4 min-w-[260px] pt-[3px] before:absolute before:inset-[1px] before:rounded-3xl before:bg-primary focus:outline-none">
                  <div className="relative flex h-full flex-col gap-2 rounded-3xl bg-white px-10 py-5 text-primaryblack shadow-2xl">
                    <Menu.Item>
                      <div
                        className={`group mb-5 flex py-1 text-[32px] font-bold leading-[1em]`}
                      >
                        {navlinks?.resources?.title}
                      </div>
                    </Menu.Item>
                    {navlinks?.resources?.items?.map(el => (
                      <Menu.Item key={el.title}>
                        {({ active }) => (
                          <Link
                            href={el.href}
                            className={`${
                              active ? "after:w-24 after:opacity-100" : ""
                            } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300`}
                          >
                            {el.title}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative">
              <Menu.Button className="nav-link">
                {navlinks?.company?.title}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in-out duration-500"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items className="absolute -right-[30px] z-[1] mt-4 min-w-[260px] pt-[3px] before:absolute before:inset-[1px] before:rounded-3xl before:bg-primary focus:outline-none">
                  <div className="relative flex h-full flex-col gap-2 rounded-3xl bg-white px-10 py-5 text-primaryblack shadow-2xl">
                    <Menu.Item>
                      <div
                        className={`group mb-5 flex py-1 text-[32px] font-bold leading-[1em]`}
                      >
                        Company
                      </div>
                    </Menu.Item>
                    {navlinks?.company?.items?.map(el => (
                      <Menu.Item key={el.title}>
                        {({ active }) => (
                          <Link
                            href={el.href}
                            className={`${
                              active ? "after:w-24 after:opacity-100" : ""
                            } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300`}
                          >
                            {el.title}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <Link
            href="/contact"
            className="hidden rounded-full border-2 border-primary bg-primary px-6 py-2 text-center text-sm text-primaryblack duration-700 ease-in-out hover:border-primaryblack hover:bg-primaryblack hover:text-primary tablet:block laptop:text-lg"
          >
            Request a demo
          </Link>
        </div>
      </div>

      {/* ================= Mobile Navigation ================= */}
      <div className="flex gap-1 tablet:hidden">
        <Link
          href="/contact"
          className="relative z-[2] flex items-center justify-center rounded-full border-2 border-primary bg-primary px-4 py-2 text-center text-base text-primaryblack"
        >
          Request a demo
        </Link>
        <Menu as="div">
          {({ open }) => {
            return (
              <>
                {open && <LockBodyScroll />}
                <WatchNav
                  open={open}
                  setOpen={openToSet =>
                    openToSet ? setNavOpened(true) : setNavOpened(false)
                  }
                />

                <Menu.Button
                  id="Menu"
                  aria-label="Menu"
                  className={`relative z-[2] flex h-[50px] w-[50px] flex-col items-center justify-center gap-[5px] rounded-full bg-neutral3 ${mode === "light" || open || navShrinked ? "" : "bg-opacity-10"}`}
                >
                  <div
                    className={`h-[2px] w-[18px] rounded-full bg-primary duration-200 ${open ? "translate-y-[7px] rotate-45" : ""}`}
                  ></div>
                  <div
                    className={`h-[2px] w-[18px] rounded-full bg-primary duration-200 ${open ? "opacity-0" : ""}`}
                  ></div>
                  <div
                    className={`h-[2px] w-[18px] rounded-full bg-primary duration-200 ${open ? "-translate-y-[7px] -rotate-45" : ""}`}
                  ></div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Menu.Items
                    className={`fixed inset-0 z-[1] h-[100dvh] w-[100dvw] overscroll-none ${open ? "overflow-y-auto" : ""}`}
                  >
                    <div className="fixed left-0 top-0 z-[1] h-[86px] w-full bg-white shadow-[0_0_20px_20px__white]"></div>
                    <div className="relative flex min-h-full flex-col bg-white p-4 pt-[130px] text-primaryblack">
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: "Inertia", delay: 0.2 }}
                      >
                        <CustomBorder top={true}>
                          <Link
                            href={navlinks?.technology?.href}
                            className="block w-full p-8 text-left text-2xl font-bold text-primary"
                          >
                            {navlinks?.technology?.title}
                          </Link>
                        </CustomBorder>
                      </motion.div>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: "Inertia", delay: 0.4 }}
                      >
                        <CustomBorder top={true}>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full p-8 text-left text-2xl font-bold text-primary">
                                  {navlinks?.solutions?.title}
                                </Disclosure.Button>
                                <AnimatePresence>
                                  {open && (
                                    <motion.div
                                      initial={{ height: 0 }}
                                      animate={{ height: "auto" }}
                                      exit={{ height: 0 }}
                                      transition={{ type: "Inertia" }}
                                      className="overflow-hidden px-8"
                                    >
                                      <motion.div
                                        initial={{ x: "10px", opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{
                                          x: "10px",
                                          opacity: 0,
                                          transition: { delay: 0 },
                                        }}
                                        transition={{
                                          type: "Inertia",
                                          delay: 0.2,
                                          duration: 0.2,
                                        }}
                                      >
                                        <div className="grid grid-cols-2 gap-5">
                                          <div>
                                            <div className="pb-3 font-bold">
                                              {navlinks?.goals?.title}
                                            </div>
                                            {navlinks?.goals?.items?.map(el => (
                                              <Menu.Item key={el.title}>
                                                {({ active }) => (
                                                  <Link
                                                    href={el.href}
                                                    className={`${
                                                      active
                                                        ? "after:w-24 after:opacity-100"
                                                        : ""
                                                    } group relative flex py-3 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                                  >
                                                    {el.title}
                                                  </Link>
                                                )}
                                              </Menu.Item>
                                            ))}
                                          </div>
                                          <div>
                                            <div className="pb-3 font-bold">
                                              {navlinks?.industries?.title}
                                            </div>
                                            {navlinks?.industries?.items?.map(
                                              el => (
                                                <Menu.Item key={el.title}>
                                                  {({ active }) => (
                                                    <Link
                                                      href={el.href}
                                                      className={`${
                                                        active
                                                          ? "after:w-24 after:opacity-100"
                                                          : ""
                                                      } group relative flex py-3 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                                    >
                                                      {el.title}
                                                    </Link>
                                                  )}
                                                </Menu.Item>
                                              ),
                                            )}
                                          </div>
                                        </div>
                                      </motion.div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </>
                            )}
                          </Disclosure>
                        </CustomBorder>
                      </motion.div>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: "Inertia", delay: 0.6 }}
                      >
                        <CustomBorder top={true}>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full p-8 text-left text-2xl font-bold text-primary">
                                  {navlinks?.resources?.title}
                                </Disclosure.Button>
                                <AnimatePresence>
                                  {open && (
                                    <motion.div
                                      initial={{ height: 0 }}
                                      animate={{ height: "auto" }}
                                      exit={{ height: 0 }}
                                      transition={{ type: "Inertia" }}
                                      className="overflow-hidden px-8"
                                    >
                                      <motion.div
                                        initial={{ x: "10px", opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{
                                          x: "10px",
                                          opacity: 0,
                                          transition: { delay: 0 },
                                        }}
                                        transition={{
                                          type: "Inertia",
                                          delay: 0.2,
                                          duration: 0.2,
                                        }}
                                      >
                                        {navlinks?.resources?.items?.map(el => (
                                          <Menu.Item key={el.title}>
                                            {({ active }) => (
                                              <Link
                                                href={el.href}
                                                className={`${
                                                  active
                                                    ? "after:w-24 after:opacity-100"
                                                    : ""
                                                } group relative flex py-3 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                              >
                                                {el.title}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </motion.div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </>
                            )}
                          </Disclosure>
                        </CustomBorder>
                      </motion.div>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: "Inertia", delay: 0.8 }}
                      >
                        <CustomBorder top={true}>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full p-8 text-left text-2xl font-bold text-primary">
                                  {navlinks?.company?.title}
                                </Disclosure.Button>
                                <AnimatePresence>
                                  {open && (
                                    <motion.div
                                      initial={{ height: 0 }}
                                      animate={{ height: "auto" }}
                                      exit={{ height: 0 }}
                                      transition={{ type: "Inertia" }}
                                      className="overflow-hidden px-8"
                                    >
                                      <motion.div
                                        initial={{ x: "10px", opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{
                                          x: "10px",
                                          opacity: 0,
                                          transition: { delay: 0 },
                                        }}
                                        transition={{
                                          type: "Inertia",
                                          delay: 0.2,
                                          duration: 0.2,
                                        }}
                                      >
                                        {navlinks?.company?.items?.map(el => (
                                          <Menu.Item key={el.title}>
                                            {({ active }) => (
                                              <Link
                                                href={el.href}
                                                className={`${
                                                  active
                                                    ? "after:w-24 after:opacity-100"
                                                    : ""
                                                } group relative flex py-3 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                              >
                                                {el.title}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </motion.div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </>
                            )}
                          </Disclosure>
                        </CustomBorder>
                      </motion.div>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: "Inertia", delay: 1 }}
                      >
                        <CustomBorder top={true}>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full p-8 text-left text-2xl font-bold text-primary">
                                  General
                                </Disclosure.Button>
                                <AnimatePresence>
                                  {open && (
                                    <motion.div
                                      initial={{ height: 0 }}
                                      animate={{ height: "auto" }}
                                      exit={{ height: 0 }}
                                      transition={{ type: "Inertia" }}
                                      className="overflow-hidden px-8"
                                    >
                                      <motion.div
                                        initial={{ x: "10px", opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{
                                          x: "10px",
                                          opacity: 0,
                                          transition: { delay: 0 },
                                        }}
                                        transition={{
                                          type: "Inertia",
                                          delay: 0.2,
                                          duration: 0.2,
                                        }}
                                      >
                                        {navlinks?.top?.items?.map(el => (
                                          <Menu.Item key={el.title}>
                                            {({ active }) => (
                                              <Link
                                                href={el.href}
                                                className={`${
                                                  active
                                                    ? "after:w-24 after:opacity-100"
                                                    : ""
                                                } group relative flex py-3 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                              >
                                                {el.title}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </motion.div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </>
                            )}
                          </Disclosure>
                        </CustomBorder>
                      </motion.div>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            );
          }}
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
