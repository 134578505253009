"use client";

const ButtonToTop = () => {
  return (
    <button
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      className="duration-300 ease-in-out hover:scale-110"
    >
      <img src="/img/btn-up.svg" alt="Up" />
    </button>
  );
};

export default ButtonToTop;
