export const navlinks = {
  top: {
    items: [
      { title: "Support", href: "/support" },
      { title: "Success Stories", href: "/success-stories" },
      { title: "Careers", href: "/company/careers" },
      { title: "Locations", href: "/company/locations" },
      { title: "Contact", href: "/contact" },
    ],
  },
  technology: { title: "Technology", href: "/technology" },
  solutions: { title: "Composable Solutions", href: "/solutions" },
  goals: {
    title: "Business Goals",
    href: "",
    icon: "/img/solutions/cta-solutions-ico1.svg",
    items: [
      {
        title: "Enhance Customer Experience",
        href: "/solutions/business-goals/enhance-customer-experience",
        icon: "/img/solutions/cta-solutions-ico3.svg",
      },
      {
        title: "Enhance Cybersecurity",
        href: "/solutions/business-goals/enhance-cybersecurity",
        icon: "/img/solutions/cta-solutions-ico5.svg",
      },
      {
        title: "Build Engagement Portals",
        href: "/solutions/business-goals/build-engagement-portals",
        icon: "/img/solutions/cta-solutions-ico7.svg",
      },
      {
        title: "Develop New Digital Products & Services",
        href: "/solutions/business-goals/develop-new-products-and-services",
        icon: "/img/solutions/cta-solutions-ico9.svg",
      },
      {
        title: "Automate Business Processes",
        href: "/solutions/business-goals/automate-business-processes",
        icon: "/img/solutions/cta-solutions-ico4.svg",
      },
      {
        title: "Data-Driven Decision making",
        href: "/solutions/business-goals/data-driven-decision-making",
        icon: "/img/solutions/cta-solutions-ico6.svg",
      },
      {
        title: "Achieve Compliance",
        href: "/solutions/business-goals/achieve-compliance",
        icon: "/img/solutions/cta-solutions-ico8.svg",
      },
      {
        title: "Create Custom Software",
        href: "/solutions/business-goals/create-custom-software",
        icon: "/img/solutions/cta-solutions-ico10.svg",
      },
    ],
  },
  industries: {
    title: "Industries",
    href: "",
    icon: "/img/solutions/cta-solutions-ico2.svg",
    items: [
      {
        title: "Government & Public services",
        href: "/solutions/industries/government-and-public-services",
        icon: "/img/solutions/cta-solutions-ico-government.svg",
      },
      {
        title: "Healthcare",
        href: "/solutions/industries/healthcare",
        icon: "/img/solutions/cta-solutions-ico-healthcare.svg",
      },
      {
        title: "Insurance",
        href: "/solutions/industries/insurance",
        icon: "/img/solutions/cta-solutions-ico-insurance.svg",
      },
      {
        title: "Agriculture",
        href: "/solutions/industries/agriculture",
        icon: "/img/solutions/cta-solutions-ico-agriculture.svg",
      },
      {
        title: "Automotive",
        href: "/solutions/industries/automotive",
        icon: "/img/solutions/cta-solutions-ico-automotive.svg",
      },
      {
        title: "Non-profit",
        href: "/solutions/industries/non-profit",
        icon: "/img/solutions/cta-solutions-ico-not-for-profit.svg",
      },
    ],
  },
  resources: {
    title: "Resources",
    href: "",
    items: [
      { title: "Success stories", href: "/success-stories" },
      { title: "Insights", href: "/blog" },
      { title: "Support", href: "/support" },
    ],
  },
  company: {
    title: "Company",
    href: "",
    items: [
      { title: "About us", href: "/company/about" },
      { title: "Leadership Team", href: "/company/leadership-team" },
      { title: "Careers", href: "/company/careers" },
      { title: "Locations", href: "/company/locations" },
      { title: "ESG", href: "/company/esg" },
      { title: "Contact us", href: "/contact" },
    ],
  },
};
