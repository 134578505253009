interface Props {
  children: React.ReactNode;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  mobileFull?: boolean;
}

const BaseContainer = ({
  children,
  size = "xl",
  mobileFull = false,
}: Props) => {
  return (
    <div
      className={`mx-auto w-full px-4 tablet:max-w-[700px]  ${size === "xs" ? "laptop:max-w-[800px]" : size === "sm" ? "laptop:max-w-[1060px]" : size === "md" ? "laptop:max-w-[1260px]" : "laptop:max-w-[1430px]"} ${size === "lg" ? "desktop:max-w-[1620px]" : ""} ${size === "xl" ? "desktop:max-w-[1920px]" : ""}`}
    >
      {children}
    </div>
  );
};

export default BaseContainer;
