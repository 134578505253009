"use client";

import Link from "next/link";
import BaseContainer from "./elements/BaseContainer";
import ButtonToTop from "./elements/ButtonToTop";
import CurrentYear from "./elements/CurrentYear";
import { navlinks } from "@/const/navlinks";
import { Disclosure, Menu } from "@headlessui/react";
import CustomBorder from "./elements/CustomBorder";
import { AnimatePresence, motion } from "framer-motion";

const Footer = () => {
  const { company, goals, industries, resources, solutions, technology } =
    navlinks;

  return (
    <div className="mt-7 laptop:mt-20">
      <BaseContainer mobileFull={true}>
        <div className="grid gap-4 text-sm laptop:grid-cols-[2fr_0.7fr_1.3fr] laptop:gap-16">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col items-center gap-4 laptop:items-start laptop:gap-2">
              <Link
                href={technology?.href}
                className="mb-2 text-lg font-bold text-primary hover:underline"
              >
                {technology?.title}
              </Link>

              <div className="mb-2 hidden cursor-default text-lg font-bold text-primary laptop:flex">
                {solutions?.title}
              </div>
            </div>
            <div className="hidden grid-cols-2 gap-16 laptop:grid">
              <div className="flex flex-col gap-3">
                <div className="font-bold">{goals?.title}</div>
                {goals?.items?.map(el => (
                  <Link
                    key={el.title}
                    href={el.href}
                    className="hover:underline"
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
              <div className="flex flex-col gap-3">
                <div className="font-bold">{industries?.title}</div>
                {industries?.items?.map(el => (
                  <Link
                    key={el.title}
                    href={el.href}
                    className="hover:underline"
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
            </div>
            <Menu
              as="div"
              className="flex flex-col items-center gap-3 laptop:hidden"
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="mb-2 cursor-pointer text-lg font-bold text-primary hover:underline">
                      {solutions?.title}
                    </Disclosure.Button>
                    <AnimatePresence>
                      {open && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: "auto" }}
                          exit={{ height: 0 }}
                          transition={{ type: "Inertia" }}
                          className="items-center overflow-hidden"
                        >
                          <motion.div
                            initial={{ x: "10px", opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{
                              x: "10px",
                              opacity: 0,
                              transition: { delay: 0 },
                            }}
                            transition={{
                              type: "Inertia",
                              delay: 0.2,
                              duration: 0.2,
                            }}
                          >
                            <div className="flex flex-col  laptop:hidden">
                              <div className="mb-3 flex flex-col gap-3">
                                <div className="font-bold">{goals?.title}</div>
                                {goals?.items?.map(el => (
                                  <Link
                                    key={el.title}
                                    href={el.href}
                                    className="hover:underline"
                                  >
                                    {el.title}
                                  </Link>
                                ))}
                              </div>
                              <div className="flex flex-col gap-3">
                                <div className="font-bold">
                                  {industries?.title}
                                </div>
                                {industries?.items?.map(el => (
                                  <Link
                                    key={el.title}
                                    href={el.href}
                                    className="hover:underline"
                                  >
                                    {el.title}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </motion.div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Disclosure>
            </Menu>
          </div>
          <div className="flex flex-col gap-4 laptop:gap-11">
            <div className="hidden  flex-col items-center gap-3 laptop:flex laptop:items-start">
              <div className="mb-2 cursor-default text-lg font-bold text-primary">
                {resources?.title}
              </div>

              <div className="hidden flex-col gap-3 laptop:flex">
                {navlinks.resources?.items?.map(el => (
                  <Link
                    key={el.title}
                    href={el.href}
                    className="hover:underline"
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
            </div>
            <Menu
              as="div"
              className="flex flex-col items-center gap-3 laptop:hidden"
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="mb-2 cursor-pointer text-lg font-bold text-primary hover:underline">
                      {resources?.title}
                    </Disclosure.Button>
                    <AnimatePresence>
                      {open && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: "auto" }}
                          exit={{ height: 0 }}
                          transition={{ type: "Inertia" }}
                          className="items-center overflow-hidden"
                        >
                          <motion.div
                            initial={{ x: "10px", opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{
                              x: "10px",
                              opacity: 0,
                              transition: { delay: 0 },
                            }}
                            transition={{
                              type: "Inertia",
                              delay: 0.2,
                              duration: 0.2,
                            }}
                          >
                            {resources?.items?.map(el => (
                              <Menu.Item key={el.title}>
                                {({ active }) => (
                                  <Link
                                    href={el.href}
                                    className={`${
                                      active
                                        ? "after:w-24 after:opacity-100"
                                        : ""
                                    } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                  >
                                    <p className="w-full text-center">
                                      {el.title}
                                    </p>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </motion.div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Disclosure>
            </Menu>
            <div className="hidden flex-col items-center gap-3 laptop:flex laptop:items-start">
              <div className="mb-2 cursor-default text-lg font-bold text-primary">
                {company?.title}
              </div>
              <div className="hidden flex-col gap-3 laptop:flex">
                {company?.items?.map(el => (
                  <Link
                    key={el.title}
                    href={el.href}
                    className="hover:underline"
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
            </div>
            <Menu
              as="div"
              className="flex flex-col items-center gap-3 laptop:hidden"
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="mb-2 cursor-pointer text-lg font-bold text-primary hover:underline">
                      {company?.title}
                    </Disclosure.Button>
                    <AnimatePresence>
                      {open && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: "auto" }}
                          exit={{ height: 0 }}
                          transition={{ type: "Inertia" }}
                          className="items-center overflow-hidden"
                        >
                          <motion.div
                            initial={{ x: "10px", opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{
                              x: "10px",
                              opacity: 0,
                              transition: { delay: 0 },
                            }}
                            transition={{
                              type: "Inertia",
                              delay: 0.2,
                              duration: 0.2,
                            }}
                          >
                            {company?.items?.map(el => (
                              <Menu.Item key={el.title}>
                                {({ active }) => (
                                  <Link
                                    href={el.href}
                                    className={`${
                                      active
                                        ? "after:w-24 after:opacity-100"
                                        : ""
                                    } group relative flex py-1 text-base text-primaryblack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:opacity-0 after:duration-300 first:pt-0 last:mb-10 last:pb-0`}
                                  >
                                    <p className="w-full text-center">
                                      {el.title}
                                    </p>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </motion.div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Disclosure>
            </Menu>
          </div>
          <div className="flex flex-col items-center gap-12 rounded-base bg-neutral3 p-12 text-center text-lg font-bold text-primary">
            <Link href="/">
              <img src="/img/logo.svg" alt="April9 Growth Tech" />
            </Link>
            <a href="tel:0731300999">07 3130 0999</a>
            <div>Level 2/10 Market Street, Brisbane, 4000, QLD</div>
            <div className="flex flex-wrap items-center justify-center gap-4">
              <a
                href="https://www.linkedin.com/company/april9digital"
                className="duration-300 ease-in-out hover:scale-[1.15]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/social-linkedin.svg" alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap items-center justify-between gap-8 py-11 laptop:flex-row">
          <div className="flex flex-col flex-wrap items-center gap-8 text-sm text-primarygray laptop:flex-row laptop:items-end">
            <img
              src="/img/footer-img-iso.webp"
              alt="ISO 27001 Information Security"
              width={180}
              height={50}
            />
            <Link href="/company/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <div>
              Copyright © <CurrentYear /> April9 Growth Tech
            </div>
          </div>
          <ButtonToTop />
        </div>
      </BaseContainer>
    </div>
  );
};

export default Footer;
